<section class="h-full overflow-hidden">

    <!-- TITLE -->
    <div class="min-h-[180px] flex justify-center items-center">
        <h1 class="text-2xl lg:text-4xl font-semibold text-darkgray">
           {{templateVars.title}}
        </h1>
    </div>

    <!-- CONTENT -->
    <div class="bg-white h-[calc(100%-180px)] overflow-y-auto">
        <!-- INNER CONTAINER -->
        <div class="container mx-auto px-5">
            
            <!-- TEXT DETAIL -->
            <p class="text-xs lg:text-sm text-darkgray/50 text-center py-5 md:max-w-[700px] md:mx-auto lg:py-10">
                {{templateVars.text}}
            </p>

            <!-- FRIENDS FAMILY -->
            <ng-container *ngIf="friendsFamily() else loader">
                
                <div class="flex flex-col gap-y-5 pb-5 lg:grid lg:grid-cols-2 lg:gap-x-5">
                    
                    <!-- NORMAL -->
                    <app-friends-wrapper
                        [title]="templateVars.normal"
                        [imageUrl]="templateVars.img"
                        [mode]="'normal'"
                        (addUser)="addFriend()"
                    >   
                        <!-- USER CARDS -->
                        <ng-container *ngIf="normal().length else noNormal">
                            <app-friends-family-card
                                *ngFor="let friend of normal()"
                                [friend]="friend"
                                (buttonClick)="deleteFriend($event, 'normal')"
                            /> 
                        </ng-container>

                        <ng-template #noNormal>
                            <div class="flex justify-center items-center">
                                <p class="text-darkgray/50 text-sm lg:text-base text-white">
                                    You have no associations yet.
                                </p>
                            </div>
                        </ng-template>

                    </app-friends-wrapper>
                    
                    <!-- INVERSE -->
                    <app-friends-wrapper
                        [title]="templateVars.inverse"
                        [imageUrl]="templateVars.img"
                        [mode]="'inverse'"
                    >   
                    <!-- USER CARDS -->
                        <ng-container *ngIf="inverse().length else noInverse">
                            <app-friends-family-card
                                *ngFor="let friend of inverse()"
                                [friend]="friend"
                                (buttonClick)="deleteFriend($event, 'inverse')"
                            />
                        </ng-container>

                        <ng-template #noInverse>
                            <div class="flex justify-center items-center">
                                <p class="text-darkgray/50 text-sm lg:text-base text-white">
                                    You have no associations yet.
                                </p>
                            </div>
                        </ng-template>
                    
                    </app-friends-wrapper>

                </div>

            </ng-container>

            <!-- LOADER -->
            <ng-template #loader>
                <div class="flex justify-center items-center">
                    <app-loader/>
                </div>
            </ng-template>

        </div>

    </div>

</section>