import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Association } from 'src/app/shared/models/association.model';

@Component({
  selector: 'app-friends-family-card',
  templateUrl: './friends-family-card.component.html',
  styleUrl: './friends-family-card.component.css'
})
export class FriendsFamilyCardComponent {

  @Input({required: true})
  public friend!: Association;

  @Output()
  public buttonClick: EventEmitter<Association> = new EventEmitter<Association>();

  public onDeleteClick() {
    this.buttonClick.emit(this.friend);
  }

}
