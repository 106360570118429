<div class="rounded-xl flex flex-col bg-white w-full min-h-[145px]">

    <div class="h-1/5 border-b border-darkgray/40 text-center">
        <span class="block p-3 text-secondary font-main font-semibold">
            {{friend.name}}
        </span>
    </div>
    
    <div class="flex-grow flex flex-col justify-around items-center">

        <p class="font-medium text-darkgray">
            Supporter Number: {{friend.associate_id}}
        </p>

        <button class="button w-5/12" (click)="onDeleteClick()">
            <span>
                Delete
            </span>
        </button>

    </div>

</div>