import { Component, Signal, computed, inject } from '@angular/core';
import { AssociationService } from 'src/app/core/services/association.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { Association, FriendsFamily } from 'src/app/shared/models/association.model';
import { ConfirmationModalParams, InformationModalParams, InputModalParams } from 'src/app/shared/models/modal.model';
import { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-friends-family',
  templateUrl: './friends-family.component.html',
  styleUrl: './friends-family.component.css'
})
export class FriendsFamilyComponent {

  private associationService: AssociationService = inject(AssociationService);
  private modalService:       ModalService       = inject(ModalService);

  protected friendsFamily:    Signal<FriendsFamily | undefined> = this.associationService.friendsFamily;
  
  public readonly normal:     Signal<Association[]>   = computed(()=> this.friendsFamily() ? this.friendsFamily()!['normal'] : []);
  public readonly inverse:    Signal<Association[]>   = computed(()=> this.friendsFamily() ? this.friendsFamily()!['inverse'] : []);

  public readonly templateVars: {title: string, text: string, normal: string, inverse: string, img: string} = {
    title:   'Friends & Family',
    text:    'To purchase for your friends and family you must request permission by selecting Add and sending the request to their registered email address. Once they have approved your request you will see them in the list below. You are able to add a maximum of 20 friends and family to your list.',
    normal:  'Supporters you can purchase on behalf of:',
    inverse: 'Supporters who can purchase on your behalf:',
    img:     '/assets/img/chelsea/friendsFamily.webp'
  }
  
  ngOnInit(): void {
    this.associationService.getFriendsFamily();
  }

  public addFriend():void {
    
    const modalParams: InputModalParams = {
      title:       'Add association',
      content:     'Enter the email of the supporter you would like to purchase on behalf of:',
      inputType:   'email',
      placeholder: 'associate email',
      onConfirm:   (email: SweetAlertResult) => email.value ? this.addAssociation(email.value) : null
    }
    
    this.modalService.createInputModal(modalParams);
  }

  public deleteFriend(association: Association , mode: 'normal'|'inverse'):void {

    const modalParams: ConfirmationModalParams = {
      title:    'Are you sure?',
      content:  'Are you sure you want to delete your friend from the list? By removing your friend you will no longer be able to purchase tickets on behalf of this supporter.',   
      onConfirm: ()=> this.deleteAssociation(association, mode)
    }

    this.modalService.createConfirmationModal(modalParams);

  }

  private addAssociation(email: string):void {

    const modalParams: InformationModalParams = {
      title:   'Completed',
      content:  'You have sent the request correctly.'
    }

    this.associationService.addAssociation(email).subscribe({
      next: ()=> this.modalService.createInformationModal(modalParams),
      error: err => this.modalService.createErrorModal({content: err.message})
    })
  }

  private deleteAssociation(association: Association, mode: 'normal'|'inverse'):void {
    
    const modalParams: InformationModalParams = {
      title:   'Completed',
      content:  'You have removed the permissions correctly.'
    }

    this.associationService.deleteAssociation(association, mode).subscribe({
      next: ()=> this.modalService.createInformationModal(modalParams),
      error: err => console.log(err)
    })

  }
  
}
