import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-friends-wrapper',
  templateUrl: './friends-wrapper.component.html',
  styleUrl: './friends-wrapper.component.css'
})
export class FriendsWrapperComponent {


  @Input({required: true})
  public title!: string;

  @Input({required: true})
  public imageUrl!: string;

  @Input({required: true})
  public mode: 'normal' | 'inverse' = 'normal';

  @Output()
  public addUser: EventEmitter<void> = new EventEmitter<void>();

  public add(): void {
    this.addUser.emit();
  }
}
