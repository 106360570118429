<div class="flex flex-col rounded-3xl bg-cover bg-main/90 lg:h-[480px] lg:max-w-[560px] lg:mx-auto" [ngClass]="{'bg-main/90': mode === 'normal', 'bg-main/80': mode === 'inverse'}">

    <div class="h-1/5 text-center border-b border-white/20 lg:flex justify-center items-center">
        <span class="block p-5 font-main text-white font-medium text-sm lg:text-xl lg:p-0">
            {{title}}
        </span>
    </div>

    <div class="flex lg:flex-col flex-col-reverse h-4/5 items-center py-5 gap-y-5 overflow-hidden mix-blend-lighten" [ngStyle]="{'background': 'transparent url('+ imageUrl +') 50% 5% / 105% no-repeat padding-box'}">

        <div [ngClass]="{'h-4/5': mode === 'normal', 'h-full': mode === 'inverse'}" class="w-11/12 flex flex-col gap-y-3 overflow-auto content">
            
            <ng-content></ng-content>

        </div>

        <ng-container *ngIf="mode === 'normal'">
            <div class="h-1/5 w-4/12">
                <button class="button-secondary" (click)="add()">
                    <span>
                        + Add
                    </span>
                </button>
            </div>
        </ng-container>
        
    </div>

</div>
